/* @flow */

import * as React from 'react'
import { LegalDocumentTemplate } from '../templates'
import text from './../../static/markdowns/mentions-legales.md'

type Props = {
  location: {
    href: string,
  },
}

export default function MentionsLegales(props: Props) {
  const { location } = props
  return (
    <LegalDocumentTemplate
      url={location.href}
      title="Mentions légales"
      name="Wino Mentions légales"
    >
      {text}
    </LegalDocumentTemplate>
  )
}
